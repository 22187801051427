function createSuklWidgetConfig() {
  var codes = ["72995289000", "29995053000", "04995082000"];
  var medicalProdIds = [13, 15];
  var vetProdIds = [19];
  var certificates = [{
    image: {
      url: "/images/footer/sukl.png",
      alt: "SUKL overenie",
      width: 91,
      height: 90
    },
    link: "https://www.sukl.sk/hlavna-stranka/slovenska-verzia/inspekcia/lekarenstvo/internetovy-vydaj-liekov-a-zdravotnickych-pomocok/zoznam-subjektov-vykonavajucich-internetovy-vydaj?page_id=4380"
  }, {
    image: {
      url: "/images/footer/Saec.png",
      alt: "SAEC logo",
      width: 51,
      height: 70
    },
    link: "https://www.bezpecnynakup.sk/sk/certifikovane-obchody"
  }, {
    image: {
      url: "/images/footer/Best-buy-award-logo.png",
      alt: "BestBuy cena",
      width: 102,
      height: 100
    }
  }];
  return {
    suklWidget: {
      codes: codes,
      medicalProdIds: medicalProdIds,
      vetProdIds: vetProdIds,
      certificates: certificates
    }
  };
}
export default defineAppConfig(createSuklWidgetConfig());